.meal {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
  }
  
  .meal h3 {
    margin: 0 0 0.25rem 0;
  }
  
  .description {
    font-style: italic;
  }
  
  .price {
    margin-top: 0.25rem;
    font-weight: bold;
    color: #ad5502;
    font-size: 1.25rem;
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .artImage {
    border: 5px solid #ffffff;
  }